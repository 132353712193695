<template>
  <div
    v-if="!isLoading"
    class="home"
    :class="[
      { 'is-connected': isConnected },
      'is-' + page,
      { 'is-loading': isLoading }
    ]"
  >
    <div class="section home-container has-text-centered">
      <div class="inner">
        <h1 class="title is-1"><img src="/Ready.svg"/></h1>

        <div>
          <button class="button is-medium" @click="init">
            <span class="text">{{ buttonText }}</span>
            <span class="overlay" :class="indicatorClass"></span>
          </button>
        </div>
      </div>
    </div>

    <div id="landscape-warning">
      <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script><dotlottie-player id="lottie-player" src="/Rotate-to-Vertical.lottie" background="#000000" speed="1" style="width: 100%; height: 100%" direction="1" playMode="normal" autoplay loop></dotlottie-player>
    </div>

    <transition name="fade">
      <div v-if="page === 'control'" class="indicator">
        <div class="inner">
          <div class="arrow" :class="arrowClass">
            <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M151.692 170L26.1538 44.4615V156.923H0V0H156.923V26.1538H44.4615L170 151.692L151.692 170Z" fill="white"/>
            </svg>
          </div>
          <div class="naut-logo">
            <img src="/logo.png"/>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <good-bye
        v-if="page === 'goodbye'"
        :count="count"
        :hash="hash"
        :insta-link="instaLink"
      />
    </transition>

    <div class="logo">
      <logo />
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client'
import GyroNorm from 'gyronorm'
import GoodBye from '@/components/Goodbye.vue'
import Logo from '@/components/Logo.vue'
import axios from 'axios'

export default {
  name: 'app',

  components: { GoodBye, Logo },

  data() {
    return {
      title: process.env.VUE_APP_START_TEXT,
      buttonText: process.env.VUE_APP_START_BUTTON,
      alpha: 0,
      status: 'start',
      socket: null,
      noGyro: false,
      isConnected: false,
      hash: 'f3gwi28vl1',
      count: 597,
      isLoading: true,
      instaLink: ''
    }
  },

  
  mounted() {
    window.addEventListener(
      "orientationchange",
      this.handleOrientationChange
    )
  },

  computed: {
    page() {
      if (this.status === 'stop') {
        return 'goodbye'
      }

      if (this.isConnected && this.status === 'start') {
        return 'control'
      }

      return 'welcome'
    },
    indicatorClass() {
      return this.alpha >= 4 ? 'has-background-primary' : 'has-background-link'
    },
    overlayClass() {
      return this.isConnected ? this.indicatorClass : 'has-background-success'
    },
    arrowClass() {
      return this.alpha >= 4 ? 'is-right' : 'is-left'
    }
  },

  created() {
    if (!window.DeviceOrientationEvent) {
      this.status = 'stop'
      this.isConnected = true
      return
    }

    axios
      .get(process.env.VUE_APP_API + '/api/client-status')
      .then((response) => {
        this.status = response.data.status
        this.instaLink = response.data.instaLink
        this.isLoading = false
      })
      .catch(() => {
        this.isLoading = false
        this.status = 'stop'
      })
  },

  methods: {
    init() {
      // feature detect
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission().then((permissionState) => {
          if (permissionState === 'granted') {
            this.listen()
          }
        })
      } else {
        this.listen()
      }
    },

    listen() {
      this.socket = io(process.env.VUE_APP_API, {
        secure: true
      })

      this.socket.on('connect', () => {
        this.socket.emit('room', 'clients')

        this.initGyro()
        this.loop()

        this.isConnected = true
      })

      this.socket.on('status', (status) => {
        this.status = status
      })

      this.socket.on('settings', (settings) => {
        const [hash, count, instaLink] = (settings || '').split(';')
        this.hash = String(hash || Math.round(Math.random() * 234235523))
        this.count = parseInt(count) || 597
        this.instaLink = instaLink
      })
    },

    initGyro() {
      const gn = new GyroNorm()

      gn.init().then(() => {
        gn.start((data) => {
          const alpha = 0 - (((data.do.alpha + 180) % 360) - 180) / 10
          this.alpha = Math.round(Math.min(Math.max(alpha, -4.5), 4.5) + 4.5)
        })
      })
    },

    loop() {
      window.setInterval(() => {
        this.socket.emit('gyro', String(parseInt(this.alpha)))
      }, 100)
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      let player =  document.getElementById("lottie-player")
      let warning = document.getElementById("landscape-warning")
      if (orientation === "landscape-primary" || orientation === "landscape-secondary") {
        //player.removeAttribute('autoplay')
        // landscape mode
        player.stop()
        player.seek(0)
        warning.style.display = "flex"
        player.play()
      }else if(orientation === "portrait-primary" || orientation === "portrait-secondary"){
        warning.style.display = "none"
        player.stop()
        player.seek(0)
      }
    }
  }
}
</script>

<style lang="scss">
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* background: url(/pattern.jpg); */
  background-color: white;
  background-size: 500px;

  &.is-goodbye {
    overflow-x: hidden;
  }

  &.is-loading * {
    transition: none !important;
  }
}

.home-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  overflow: hidden;

  .title {
    margin-top: 7rem;
    .home:not(.is-welcome) & {
      transition: 0.3s;
      opacity: 0;
    }
  }

  .button {
    margin-top: auto;
    border: none;
    width: calc(100vw - 6rem);
    height: calc(100vw - 6rem);
    max-width: 18rem;
    max-height: 18rem;
    border-radius: 100rem;
    font-size: $size-2 !important;
    text-transform: uppercase;
    white-space: normal !important;
    color: white !important;
    line-height: 1.2;
    position: relative;
    background: none;
    box-shadow: none !important;
    outline: none !important;
    .text {
      display: inline-block;
      position: relative;
      z-index: 10;
      .home:not(.is-welcome) & {
        transition: 0.3s;
        transform: scale(0.8);
        opacity: 0;
      }
    }
    .overlay {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      z-index: 0;
      transition: 0.4s;
      .home.is-goodbye & {
        opacity: 0;
      }
      .home:not(.is-welcome) & {
        transform: scale(7);
      }
      .home:not(.is-connected) & {
        background-color: $success !important;
      }
    }
  }
}

.section {
  padding: 3rem !important;
}

.indicator {
  width: 100%;
  height: 100%;
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  &.fade-enter-active {
    transition: 0.4s;
  }
  &.fade-leave-active {
    transition: 0.2s;
  }
  &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: scale(0.8);
  }
  &.fade-enter-active {
    transition-delay: 0.2s;
  }
}

.arrow {
  padding: 0 10vw;

  
  &.is-right {
    transform: rotate(90deg);
  }
  svg {
    width: 100%;
    path {
      fill: white !important;
    }
  }
}

.logo {
  position: absolute;
  top: 10rem;
  left: 0;
  z-index: 10000;
  transform: rotate(-90deg);
  transform-origin: top left;
  transition: 0.3s;

  path {
    transition: 0.2s;
    fill: white;
  }

  .home.is-goodbye & {
    transform: rotate(-90deg) translateY(-4rem);
  }

  .home:not(.is-control) & {
    path {
      fill: black;
    }
  }

  svg {
    width: 130.45px;
  }
}

.inner {
  max-width: 32rem;
  margin: 0 auto;
  height: 100%;
}

.naut-logo{
  position: absolute;
  right: -2px;
  bottom: 2em;
  max-width: 2.5em;

}


#landscape-warning {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
  background: black;
  z-index: 1000000;
  font-size: 2em;
  justify-content: center;
  align-items: center;
}

@media (orientation: portrait) {
  #landscape-warning {
    display: none;
  }
}
</style>
<style scoped>
.home-container .title {
  margin-top: 9rem;
  font-size: 3.75em;
  font-style: italic;
  color: white;
}

.home.is-welcome {
  background-image: url('/Background-tile-Black.png');
}
</style>
