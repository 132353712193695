<template>
  <div class="section goodbye">
    <div class="inner">
      <h1 class="title is-4">{{ title }}</h1>
      <br />
      <div class="card">
        <div class="card-image">
          <img id="finalImage" src="" />
        </div>
      </div>
      <div class="shareButton">
        <button @click="share">
          <img class="aos-share" src="/aos-share.svg" /><img
            class="ios-share"
            src="/ios-share.svg"
          />Share & Win
        </button>
      </div>
      <div class="findus">Find us on</div>
      <div class="sharing">
        <div class="share-buttons">
          <span network="website" class="share">
            <a href="https://naut.ch/" target="_blank"><img src="/Icon-Website.png"/></a>
          </span>
          <span network="linkedin" class="share">
            <a href="https://www.linkedin.com/company/nautgmbh/" target="_blank"
              ><img src="/Icon-Linkedin.png"
            /></a>
          </span>
          <span network="instagram" class="share">
            <a href="https://www.instagram.com/nautstories/" target="_blank"
              ><img src="/Icon-Instagram.png"
            /></a>
          </span>
          <span network="whatsapp" class="share">
            <a href="https://wa.me/41786028221" target="_blank"><img src="/Icon-Whatsapp.png"/></a>
          </span>
          <span network="email" class="share">
            <a href="mailto:contact@naut.ch" target="_blank"><img src="/Icon-Email.png"/></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 597
    },
    hash: {
      type: String,
      default: 'a82hf31d'
    },
    instaLink: {
      type: String,
      default: 'https://www.instagram.com/nautstories'
    },
  },

  data() {
    return {
      title: process.env.VUE_APP_END_TITLE,
      shareText: process.env.VUE_APP_END_SHARE_TEXT,
      sharing: {
        hashtags: process.env.VUE_APP_SHARE_HASHTAGS,
        url: process.env.VUE_APP_SHARE_URL
      },
      blob: ""
    }
  },

  computed: {
    sharingTitle() {
      return process.env.VUE_APP_SHARE_TITLE.replace('@count', this.count)
    },

    sharingDescription() {
      return process.env.VUE_APP_SHARE_TEXT.replace('@count', this.count)
    },

    description() {
      return process.env.VUE_APP_END_BOX_TEXT.replace('@count', this.count)
    }
  },

  async created(){
    await this.loadImage();
  },

  methods: {
    loadImage() {
      fetch('https://google-naut-server-682240f0f977.herokuapp.com/getImage', {mode: 'cors'})
        .then((response) => response.blob())
        .then((blob) => {
          this.blob = blob
          const objectURL = URL.createObjectURL(blob);
          document.getElementById('finalImage').src = objectURL;
          return objectURL;
        })
    },
    share() {
      var file = new File([this.blob], 'togetherforthebetter.png', {type:"image/png"})
      const data = {
        files: [file,],
        title: '#togetherforthebetter',
        text: '#togetherforthebetter'
      }
      if (navigator.canShare(data)) {
        navigator.share(data)
      }
    }
  }
}
</script>

<style lang="scss">
.goodbye {
  position: relative;
  z-index: 10000;
  margin: 0 -$size-7;
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.3s;
  }
  &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .naut-link {
    font-weight: 500;
    color: black;
    display: inline-block;
    margin-right: auto;
    text-align: left;
  }
}

.credits {
  font-size: 11px;
  text-align: left;
  color: $grey;
}

.card{
  -webkit-box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3),0 0 0 1px rgba(10,10,10,.02);
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.3),0 0 0 1px rgba(10,10,10,.02);
  line-height:0px;
}

.card-content {
  padding: $size-7 !important;
}

.share-buttons {
  display: flex;
  align-items: center;
  margin: auto;
}

.sharing {
  display: flex;
  font-size: $size-6;
  margin-top: $size-5;
  align-items: center;

  .share {
    margin-right: 1em;
    display: flex;
    color: black;
    width: 3em;
    height: 3em;
    flex: 0 0 3em;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }

  i {
    vertical-align: bottom;
  }
}
.home.is-goodbye {
  background-image: url('/Background-tile-White.png');
}

.findus {
  font-size: 1.2em;
  font-weight: 500;
}

.title.is-4 {
  font-size: 2.4em !important;
  margin-bottom: 0em !important;
  font-weight: 500;
}

.shareButton {
  margin: 1.5em 0px 1.2em 0px;
}

.shareButton button {
  border-radius: 8px;
  padding: 0.4em 1.2em 0.5em 1.2em;
  font-weight: 550;
  border: none;
  background-color: black;
  color: white;
  font-size: 1.7em;
}

.aos-share {
  position: relative;
  top: 0.3em;
  margin-right: 0.6em;
  max-height: 1.1em;
}

.ios-share {
  position: relative;
  top: 0.25em;
  margin-right: 0.6em;
  max-height: 1.1em;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .ios-share {
    display: inline;
  }
  .aos-share {
    display: none;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .ios-share {
    display: none;
  }
  .aos-share {
    display: inline;
  }
}
</style>
